import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';


class SessionsTable extends React.Component {

  state = {
  };

  createSortHandler = (property) => {
    this.props.onRequestSort(property);
  };

  render() {
    const {
      classes,
      sessions,
      page,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
      totalCount,
      order,
      orderBy
    } = this.props;

    return (
      <Paper className={classes.paper}>
        <Table style={{ tableLayout: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'full_name'}
                  direction={orderBy === 'full_name' ? order : 'asc'}
                  onClick={() => this.createSortHandler('full_name')}
                  hideSortIcon={false}
                >
                  Isiku nimi
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'kirjeldus'}
                  direction={orderBy === 'kirjeldus' ? order : 'asc'}
                  onClick={() => this.createSortHandler('kirjeldus')}
                  hideSortIcon={false}
                >
                  Kirjeldus
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map(n => {
              return (
                <TableRow
                  hover
                  onClick={e => navigate("/victim/" + n.client_id + "/" + n.incident_id + "/" + n.id)}
                  key={n.name}
                >
                  <TableCell>
                    {n.full_name}
                  </TableCell>
                  <TableCell>
                    {n.kirjeldus}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 20, 50, 100, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
  
};

SessionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SessionsTable;